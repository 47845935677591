import { gql } from "@apollo/client";

import { VAULT_ITEM_FRAGMENT } from "@/api/fragments/vault";

export const GET_VAULT = gql`
  query GetVault {
    vault {
      orgId
      userId
      items {
        ...VaultItemFragment
      }
      createdAt
      updatedAt
    }
  }
  ${VAULT_ITEM_FRAGMENT}
`;

export const CREATE_VAULT = gql`
  mutation CreateVault {
    createVault {
      orgId
      userId
      items {
        ...VaultItemFragment
      }
      createdAt
      updatedAt
    }
  }
  ${VAULT_ITEM_FRAGMENT}
`;

export const GET_VAULT_ITEM = gql`
  query GetVaultItem($extId: String!) {
    vaultItem(extId: $extId) {
      ...VaultItemFragment
    }
  }
  ${VAULT_ITEM_FRAGMENT}
`;

export const CREATE_VAULT_ITEM = gql`
  mutation createVaultItem(
    $item: VaultItemInput!
    $withCreatePrefilledLicense: WithCreatePrefilledLicenseInput
  ) {
    createVaultItem(item: $item, withCreatePrefilledLicense: $withCreatePrefilledLicense) {
      orgId
      userId
      items {
        ...VaultItemFragment
      }
      createdAt
      updatedAt
    }
  }
  ${VAULT_ITEM_FRAGMENT}
`;

export const UPDATE_VAULT_ITEM = gql`
  mutation updateVaultItem(
    $item: VaultItemInput!
    $withCreatePrefilledLicense: WithCreatePrefilledLicenseInput
    $extId: String
  ) {
    updateVaultItem(
      item: $item
      withCreatePrefilledLicense: $withCreatePrefilledLicense
      extId: $extId
    ) {
      orgId
      userId
      items {
        ...VaultItemFragment
      }
      createdAt
      updatedAt
    }
  }
  ${VAULT_ITEM_FRAGMENT}
`;

export const REMOVE_VAULT_ITEM = gql`
  mutation removeVaultItem($extId: String!) {
    removeVaultItem(extId: $extId) {
      orgId
      userId
      items {
        ...VaultItemFragment
      }
      createdAt
      updatedAt
    }
  }
  ${VAULT_ITEM_FRAGMENT}
`;

export const CHECKOUT_VAULT = gql`
  mutation CheckoutVault($vaultItemExtIds: [String]) {
    checkoutVault(vaultItemExtIds: $vaultItemExtIds) {
      extId
      status
    }
  }
`;
