//
export const ngs_config = {
  //
  contactEmail: "yourEmail@domain.com",
  //

  licenseOptions: {
    PERSONAL: {
      title: "Personal use",
      showSuffix: true,
    },
    PUBLICATION: {
      title: "Other licenses",
      showSuffix: true,
    },
  },
  allowMobileViewOnDev: false,

  defaultCurrency: "USD",
};
