import { clsMerge } from "@artifactlabs/shared-react-utils";
import React from "react";
import { FunctionComponent, HTMLAttributes } from "react";

import { poppins } from "@/utils/font";

type Props = HTMLAttributes<HTMLAnchorElement>;

const STORAGE_URL = process.env.NEXT_PUBLIC_S3_PUBLIC_ASSETS_BUCKET_HOST_NAME!;

const TermsAndConditionsButton: FunctionComponent<Props> = ({ className, children, ...rest }) => {
  //
  let link = `https://${STORAGE_URL}/documents/terms-and-conditions/Artevault_Platform+Terms+and+Conditions.pdf`;

  //
  return (
    <a
      className={clsMerge(
        "text-center text-sm text-[#0066CC] underline visited:text-[#0066CC]",
        "py-2 pr-2",
        className,
        poppins.className,
      )}
      {...rest}
      href={link}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children ?? "Terms and Conditions"}
    </a>
  );
};

export default TermsAndConditionsButton;
