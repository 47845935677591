import { clsMerge } from "@artifactlabs/shared-react-utils";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { FunctionComponent } from "react";

import SuccessTick from "@/components/Icons/SuccessTick";
import { useCloseModal } from "@/hooks/useModal";
import { poppins } from "@/utils/font";

interface Props {
  status: "success" | "error" | "warning" | "info";
  title: string;
  description: string;
  mainActionText: string;
  closeAfterMainAction?: boolean;
  secondaryActionText?: string;
  onConfirm: () => void;
  onSecondaryClick?: () => void;
}

const GenericModalView: FunctionComponent<Props> = ({
  status = "success",
  title,
  description,
  mainActionText,
  secondaryActionText,
  onSecondaryClick,
  closeAfterMainAction = true,
  onConfirm,
}) => {
  const closeModal = useCloseModal();

  return (
    <div
      className={clsMerge(
        "flex flex-col items-center overflow-y-auto bg-white antialiased",
        "h-[100vh] w-[100vw] gap-8 px-[15px] py-[30px]",
        "md:h-fit md:max-h-[97vh] md:max-w-[456px] md:rounded-xl md:px-4 md:py-[30px]",
        poppins.className,
      )}
    >
      {/* Body */}
      <div className="flex h-[100vh] flex-col items-center justify-center gap-4 md:h-fit">
        {/* Icons */}
        <div>
          {status === "success" && <SuccessTick className="h-16 w-16 pb-10" />}
          {status === "error" && (
            <ExclamationCircleIcon className="mb-4 h-16 w-16" fill="#C63328" />
          )}
        </div>

        {/* Title */}
        <div
          className={clsMerge(
            "pb-4 text-center text-base font-bold text-[#343434]",
            poppins.className,
          )}
        >
          {title}
        </div>

        {/* Description */}
        <div
          className={clsMerge(
            "text-center text-sm font-medium leading-tight text-[#343434]",
            poppins.className,
          )}
        >
          {description}
        </div>
      </div>

      {/* Actions */}
      <div
        className={clsMerge(
          "fixed bottom-[30px] flex w-full flex-col-reverse gap-4 px-[15px]",
          "md:relative md:bottom-0 md:h-[30%] md:flex-row md:justify-center",
        )}
      >
        {secondaryActionText && (
          <button
            className={clsMerge(
              "flex h-10 items-center justify-center rounded-[4px] border border-[#1C2442] px-4 text-sm font-medium leading-[20px]",
              "w-[100%] md:w-[120px]",
              poppins.className,
            )}
            onClick={() => {
              onSecondaryClick && onSecondaryClick();
              closeModal();
            }}
          >
            {secondaryActionText}
          </button>
        )}

        <button
          className={clsMerge(
            "flex h-10 items-center justify-center rounded-[4px] bg-[#1C2442] px-4 text-sm font-medium leading-[20px] text-white hover:bg-[#35447d]",
            "w-[100%] md:w-[120px]",
            poppins.className,
          )}
          onClick={() => {
            onConfirm && onConfirm();
            if (closeAfterMainAction) {
              closeModal();
            }
          }}
        >
          {mainActionText}
        </button>
      </div>
    </div>
  );
};

export default GenericModalView;
