/*******************************************************************************************************
 **** this component represents a container for displaying content with different padding patterns. ****
 **** The padding pattern is determined by the `variant` prop,                                      ****
 **** It's designed to match the content padding patterns used in different pages in Figma.         ****
 *******************************************************************************************************/

import { clsMerge } from "@artifactlabs/shared-react-utils";
import { FunctionComponent, HTMLAttributes } from "react";

interface IAppContentContainer extends HTMLAttributes<HTMLDivElement> {
  variant?: "content-40" | "content-120" | "content-160";
}

const AppContentContainer: FunctionComponent<IAppContentContainer> = ({
  variant,
  className,
  children,
  ...rest
}) => {
  return (
    <div
      className={clsMerge(
        {
          "px-10 md:px-[160px]": variant === "content-160",
          "px-10 md:px-[120px]": variant === "content-120",
          "px-10 md:px-[40px]": variant === "content-40",
        },
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default AppContentContainer;
