import { useQuery } from "@apollo/client";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import { motion } from "framer-motion";
import Link from "next/link";
import { useState, useEffect } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useRecoilState } from "recoil";

import { GET_VAULT } from "@/api/queries/vault";
import { DynamicUserInfo } from "@/components/Header/dynamics";
import LoginButton from "@/components/LoginButton";
import LogoutButton from "@/components/LogoutButton";
import { GetVaultQuery, GetVaultQueryVariables } from "@/gql/graphql";
import { useCustomTranslation } from "@/hooks/useCustomTranslation";
import { useAppNavigation } from "@/hooks/useWebAppNavigation";
import FeaturesFlagsAtom from "@/recoil/featureFlags";

interface HeaderMenuProps {
  isAuthenticated?: boolean;
  mode: "desktop" | "mobile";
  isMenuOpen?: boolean;
  onMenuClose?: () => void;
}

const HeaderMenu = (props: HeaderMenuProps) => {
  const appNavigation = useAppNavigation();

  const [featuresFlags] = useRecoilState(FeaturesFlagsAtom);

  const { data } = useQuery<GetVaultQuery, GetVaultQueryVariables>(GET_VAULT, {
    fetchPolicy: "cache-and-network",
  });

  const numItems = data?.vault?.items.length || 0;

  const { t } = useCustomTranslation("home");

  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (Object.keys(featuresFlags).length > 0) {
      setHasLoaded(true);
    }
  }, [featuresFlags]);

  // Disable scrolling when menu is open
  useEffect(() => {
    if (props.isMenuOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling when menu is open
    }
    return () => {
      document.body.style.overflow = "auto"; // Enable scrolling when component unmounts
    };
  }, [props.isMenuOpen]);

  //
  const getRenderMenuItems = () => {
    const result: any = [];
    //
    appNavigation.map(item => {
      const search = new URLSearchParams(item.query);
      const query = search.toString();
      const href = `${item.href}${query ? `?${query}` : ""}`;

      //
      switch (item.name) {
        case "requests": {
          if (featuresFlags.checkoutFlow?.mode !== "REQUEST_PURCHASE") {
            return null;
          }
          break;
        }
        case "my purchases": {
          if (featuresFlags.checkoutFlow?.mode !== "DIRECT_PURCHASE") {
            return null;
          }
          break;
        }
        default: {
          break;
        }
      }

      result.push({
        ...item,
        href,
      });
    });

    return result;
  };

  //
  const renderMenuItemRow = (row: any) => {
    return (
      <Link href={row.href} key={row.name} onClick={handleOnItemClick}>
        <div className="flex h-[48px] w-full items-center justify-start gap-1 border-b border-b-gray-300 p-[12px] align-middle font-poppins text-sm font-medium text-gray-700">
          {(row.name ?? "").toUpperCase()}
          {row.showIcon && numItems > 0 && (
            <div className="flex h-[16px] w-[20px] flex-col items-center justify-center gap-2.5 rounded-[100px] py-[6px]">
              <div className="text-center text-[9px] font-medium tracking-wide text-white">
                {numItems}
              </div>
            </div>
          )}
        </div>
      </Link>
    );
  };

  //
  const AccordionItem = ({ header, showArrow, ...rest }: any) => (
    <Item
      {...rest}
      buttonProps={{
        className:
          "flex h-[48px] w-full items-center justify-start gap-1 p-[12px] align-middle font-poppins text-sm font-medium text-gray-700",
      }}
      className="border-b border-b-gray-300"
      contentProps={{
        className: "transition-height duration-200 ease-out",
      }}
      header={({ state: { isEnter } }) => (
        <>
          {header}
          {showArrow && (
            <FontAwesomeIcon
              className={`ml-auto transition-transform duration-200 ease-out ${
                isEnter && "rotate-180"
              }`}
              color="black"
              icon={faChevronDown}
              size="sm"
              style={{ maxWidth: "20px", maxHeight: "20px" }}
            />
          )}
        </>
      )}
      panelProps={{ className: "" }}
    />
  );

  //
  const handleOnItemClick = () => {
    props?.onMenuClose?.();
  };

  //
  return (
    <>
      {/* Desktop Menu */}
      {hasLoaded && props.mode === "desktop" && (
        <nav aria-label="Global" className="hidden flex-1 items-center justify-center md:flex">
          <div className="flex items-center gap-x-[24px] ">
            {(getRenderMenuItems() ?? []).map((item: any) => {
              return (
                <button key={item?.name ?? ""}>
                  <Link
                    className={clsMerge(
                      "relative text-base font-medium uppercase leading-[24px] tracking-widest text-black",
                    )}
                    href={item.href}
                  >
                    {item.name}
                    {item.current && (
                      <motion.div
                        className="absolute -bottom-2 h-[2.5px] w-full bg-black"
                        layoutId="global.header.underline"
                      />
                    )}
                  </Link>
                </button>
              );
            })}
          </div>
        </nav>
      )}

      {/* Mobile Menu - Hamburger Menu */}
      {hasLoaded && props.mode === "mobile" && (
        <SlidingPane
          className="overflow-y-hidden"
          isOpen={props.isMenuOpen}
          overlayClassName="mt-[64px] z-40 border-t border-gray-200"
          width="100%"
          hideHeader
          onRequestClose={() => {
            props?.onMenuClose?.();
          }}
        >
          <div
            className="-mx-[32px] -my-[24px] flex flex-col justify-between"
            style={{ height: "calc(100% + 48px)" }}
          >
            <div className="h-auto">
              {/* User Profile */}
              {props.isAuthenticated && (
                <div className="flex h-[54px] w-[100] items-center border-b border-gray-200 pl-[16px] align-middle">
                  <DynamicUserInfo />
                </div>
              )}

              {/* Menu Items */}
              <div className="px-[15px]">
                {(getRenderMenuItems() ?? []).map((item: any) => {
                  return renderMenuItemRow(item);
                })}

                {/* Vault */}
                {renderMenuItemRow({
                  name: t("VAULT"),
                  href: "/vault",
                  current: false,
                  showIcon: true,
                })}

                {/* My Profile */}
                {props.isAuthenticated && (
                  <Accordion className="space-y-1" transitionTimeout={200} transition>
                    <AccordionItem
                      className="flex h-[46px] items-center border-b border-gray-200 py-2 align-middle text-sm font-medium text-gray-700"
                      header={`${t("MY_ACCOUNT") ?? ""}`.toUpperCase()}
                      key={`my_account`}
                      showArrow={true}
                    >
                      <ul className="ml-[30px] flex flex-col gap-2">
                        <li className="flex items-center align-middle" key={`profile_my_profile`}>
                          <Link href="/my-profile" onClick={handleOnItemClick}>
                            <div className="w-[80vw] items-center justify-center px-4 py-4 font-poppins text-sm text-gray-500  hover:bg-gray-100 hover:text-gray-700">
                              {t("PROFILE") ?? ""}
                            </div>
                          </Link>
                        </li>

                        {featuresFlags.checkoutFlow.mode !== "DIRECT_PURCHASE" && (
                          <li
                            className="flex items-center align-middle"
                            key={`profile_my_licenses`}
                          >
                            <Link href="/my-profile/licenses" onClick={handleOnItemClick}>
                              <div className="w-[80vw] items-center justify-center px-4 py-4 font-poppins text-sm text-gray-500 [text-align:_inherit] hover:bg-gray-100 hover:text-gray-700">
                                {t("MY_LICENSES") ?? ""}
                              </div>
                            </Link>
                          </li>
                        )}
                      </ul>
                    </AccordionItem>
                  </Accordion>
                )}
              </div>
            </div>

            <div className="flex-start flex items-center justify-center align-middle">
              {!props.isAuthenticated && <LoginButton className="my-[20px] w-[90%]" />}
              {props.isAuthenticated && <LogoutButton className="my-[20px] w-[90%]" />}
            </div>
          </div>
        </SlidingPane>
      )}
    </>
  );
};

export default HeaderMenu;
