import { useMutation } from "@apollo/client";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FunctionComponent, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { CREATE_CHECKOUT_SESSION_MUTATION } from "@/api/queries/checkout";
import {
  CreateCheckoutSessionMutation,
  CreateCheckoutSessionMutationVariables,
} from "@/gql/graphql";
import featureFlagsAtom from "@/recoil/featureFlags";
import tenantAtom from "@/recoil/tenant";
import { getOrgConfigByTenantId } from "@/utils/org";

export interface Props {
  totalPrice: number;
  extIds: [string];
}

// TODO should generate in server side
function generateOrderUniqueId(prefix: string): string {
  const timestamp = Date.now();
  const randomComponent = Math.floor(Math.random() * 10000);
  return `${prefix}-${timestamp}-${randomComponent}`;
}

function calculateUnitAmount(totalPrice: any): number {
  const price = Number(totalPrice);

  if (!isNaN(price)) {
    return Math.round(price * 100);
  } else {
    throw new Error("Invalid totalPrice: cannot be converted to a number.");
  }
}

const mapOrgCurrencyToStripeCurrency: { [key: string]: string } = {
  USD: "usd",
  HKD: "hkd",
};

const StripeEmbeddedCheckout: FunctionComponent<Props> = ({ totalPrice, extIds }) => {
  const [clientSecretEmbedded, setClientSecretEmbedded] = useState(null as string | null);
  const [stripePublishableKey, setStripePublishableKey] = useState(null as string | null);

  const [createCheckoutSession, { loading, data, called }] = useMutation<
    CreateCheckoutSessionMutation,
    CreateCheckoutSessionMutationVariables
  >(CREATE_CHECKOUT_SESSION_MUTATION);

  const featureFlagState = useRecoilValue(featureFlagsAtom);

  const tenant = useRecoilValue(tenantAtom);
  const config = getOrgConfigByTenantId(tenant.id);
  const stripeCurrency = mapOrgCurrencyToStripeCurrency[config.defaultCurrency];

  useEffect(() => {
    const fetchCheckoutSession = async () => {
      if (!called && !loading && !clientSecretEmbedded) {
        await createCheckoutSession({
          variables: {
            orderKey: generateOrderUniqueId("Order"),
            unitAmount: calculateUnitAmount(totalPrice),
            currency: stripeCurrency,
            extIds,
          },
        });
      }
    };
    fetchCheckoutSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !stripePublishableKey &&
      featureFlagState?.checkoutOptions?.stripe?.enabled &&
      featureFlagState?.checkoutOptions?.stripe?.publishableKey
    )
      setStripePublishableKey(featureFlagState?.checkoutOptions?.stripe?.publishableKey);
  }, [stripePublishableKey, featureFlagState?.checkoutOptions?.stripe]);

  useEffect(() => {
    const clientSecret = data?.createCheckoutSession?.clientSecret;
    if (clientSecret && !clientSecretEmbedded) setClientSecretEmbedded(clientSecret);
  }, [clientSecretEmbedded, data]);

  return (
    <div id="checkout">
      {clientSecretEmbedded && stripePublishableKey && (
        <EmbeddedCheckoutProvider
          options={{ clientSecret: clientSecretEmbedded }}
          stripe={loadStripe(stripePublishableKey)}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default StripeEmbeddedCheckout;
