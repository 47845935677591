//

export const usn_config = {
  //
  contactEmail: "mail@ultrasupernew.com",
  //
  allowMobileViewOnDev: false,

  defaultCurrency: "USD",
};
