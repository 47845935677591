import { useRouter } from "next/router";
import { useMemo } from "react";

export const useAppNavigation = () => {
  const router = useRouter();
  const navigation = useMemo(
    () => [
      {
        name: "gallery",
        query: undefined,
        href: "/",
        current: router.asPath === "/" || router.asPath.includes("/gallery"),
        isLoginRequired: false,
      },
      {
        name: "requests",
        href: "/my-requests",
        query: {
          tab: "pending",
        },
        current: router.asPath.includes("/my-requests"),
        isLoginRequired: true,
      },
      {
        name: "my purchases",
        href: "/my-purchases",
        query: {
          tab: "purchased",
        },
        current: router.asPath.includes("/my-purchases"),
        isLoginRequired: true,
      },
      {
        name: "my collection",
        href: "/my-collection",
        query: {
          tab: "active",
        },
        current: router.asPath.includes("/my-collection"),
        isLoginRequired: true,
      },
    ],
    [router.asPath],
  );

  return navigation;
};
