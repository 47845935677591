import useTranslation from "next-translate/useTranslation";
import { useRecoilState } from "recoil";

import tenantAtom from "@/recoil/tenant/atom";

export const useCustomTranslation = (namespace: string) => {
  //
  const [tenant] = useRecoilState(tenantAtom);

  //
  const { t } = useTranslation(namespace);
  const { t: tCommon } = useTranslation("common");

  const tCustom = (key: string, query?: object, options?: object) => {
    //
    const splitKey = key.split(".");
    if (splitKey[0] === "common") {
      return tCommon(`${splitKey[1]}.${splitKey[2]}`, query, options);
    }

    const mergedKey = `${tenant.id ?? "general"}.${key}`;
    return t(mergedKey, query, {
      ...options,
      fallback: t(`general.${key}`, query, options),
    });
  };

  return { t: tCustom };
};
