//
export const titanic_config = {
  //
  contactEmail: "",
  //
  allowMobileViewOnDev: false,
  //
  licenseLink: `https://${process.env.NEXT_PUBLIC_S3_PUBLIC_ASSETS_BUCKET_HOST_NAME!}/documents/content-licensing/T-TITANIC/Artevault_Content+Licensing+Agreement(TITANIC).pdf`,
  //
  showLicenseLinkOnMyCollectionPage: true,

  defaultCurrency: "USD",
};
