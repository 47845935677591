import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { useRecoilValue } from "recoil";

import { useCloseDrawer } from "@/hooks/useDrawer";
import UIAtom from "@/recoil/UI";

const DrawerView: React.FunctionComponent<{
  open: boolean;
  drawerView: string;
  closeDrawer: (...args: any) => void;
  extraProps?: Record<string, unknown>;
}> = ({ open, closeDrawer }) => {
  return (
    <Transition.Root as={React.Fragment} show={open}>
      <Dialog as="div" open={open} onClose={closeDrawer}>
        <div className="fixed inset-0 z-50 overflow-y-auto"></div>
      </Dialog>
    </Transition.Root>
  );
};

// eslint-disable-next-line react/no-multi-comp
const Drawer: React.FunctionComponent = () => {
  const UI = useRecoilValue(UIAtom);
  const closeDrawer = useCloseDrawer();

  return (
    <DrawerView
      closeDrawer={closeDrawer}
      drawerView={UI.drawerView}
      extraProps={UI.drawerProps}
      open={UI.displayDrawer}
    />
  );
};

export default Drawer;
