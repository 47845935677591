import { atom } from "recoil";

export interface State {
  profile: any;
}

const initialState: State = {
  profile: null,
};

const userAtom = atom({
  key: "UserAtom",
  default: initialState,
});

export default userAtom;
