//

export const platform_config = {
  //
  contactEmail: "shopify@globephotos.com",
  //
  allowMobileViewOnDev: false,

  defaultCurrency: "USD",
};
