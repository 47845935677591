import { useAuthState } from "@artifactlabs/react-auth";
import { clsMerge } from "@artifactlabs/shared-react-utils";
export interface Props {
  className?: String;
}

const LogoutButton: React.FunctionComponent<Props> = ({ className }) => {
  const { logout } = useAuthState();

  const handleLogout = async () => {
    await logout("/");
  };

  return (
    <>
      <button
        className={clsMerge(
          "inline-flex h-10 w-full flex-col items-center justify-center rounded ",
          "border border-slate-800 px-4 text-center text-sm font-medium ",
          "leading-tight text-[#1C2442]",
          className,
        )}
        onClick={handleLogout}
      >
        Sign Out
      </button>
    </>
  );
};

export default LogoutButton;
