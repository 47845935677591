import { Spin as Hamburger } from "hamburger-react";

interface HambergerButtonProps {
  state: boolean;
  isOpen: (state: boolean) => void;
}

const HambergerButton = (props: HambergerButtonProps) => {
  return (
    <>
      <Hamburger
        size={20}
        toggled={props.state}
        onToggle={toggled => {
          if (toggled) {
            props.isOpen(true);
          } else {
            props.isOpen(false);
          }
        }}
      />
    </>
  );
};

export default HambergerButton;
