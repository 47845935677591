export const zHeader = {
  className: "z-40",
};

export const zSearchBar = {
  className: "z-30",
};

export const zArtworkMediaTypeDropdown = {
  className: "z-40",
};

export const zSearchBarOptionItem = {
  className: "z-10",
};

export const zModal = {
  className: "z-60",
};

export const zModalClickableBackdrop = {
  className: "z-50",
};
