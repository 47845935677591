import { gql } from "@apollo/client";

export const VAULT_ITEM_FRAGMENT = gql`
  fragment VaultItemFragment on VaultItem {
    extId
    refId
    price
    artwork {
      extId
      src
      importRef {
        name
        value
      }
      metadata {
        name
        creationDate
        creatorName
        copyrightOrRestrictions
        materialsAndTechnology
        seriesName
        relevantDates
        relevantGeos
        dimension {
          width
          height
          depth
          unit
        }
        assetType
        assetFileFormat
      }
      assets {
        type
        size
        width
        height
        dpi
        fileSize
      }
    }
    itemDetails {
      currency
      conditions {
        use
        territory
        usage
        duration
        resolution
      }
      details {
        title
        creator
        formatOfReproduction
        language
        useOfImage
        publisher
        printRun
        retailPrice
        remark
        description
      }
    }
    invalid {
      reason
      timestamp
    }
    createdAt
    updatedAt
  }
`;
