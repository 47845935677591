import { clsMerge } from "@artifactlabs/shared-react-utils";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Fragment, HTMLAttributes, LabelHTMLAttributes, FunctionComponent, useMemo } from "react";

import ChevronArrowDown from "@/components/Icons/ChevronArrowDown";

export type FormSelectOption = {
  id: string;
  name: string;
};

interface FormSelectWithLabelProps {
  selected: string;
  handleChange: (...args: any) => void;
  options: FormSelectOption[];
  label: string;
  disabled?: boolean;
  inlineHelperText?: string;
  error?: string;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  inputProps?: any;
  errorProps?: HTMLAttributes<HTMLDivElement>;
}

const FormSelectWithLabel: FunctionComponent<FormSelectWithLabelProps> = ({
  selected,
  handleChange,
  disabled = false,
  options,
  label,
  inlineHelperText,
  error,
  labelProps,
  errorProps,
}) => {
  const { className: labelClassNames, ...labelRestProps } = labelProps ?? {};
  const { className: errorClassName, ...errorRestProps } = errorProps ?? {};

  const selectedOptions = useMemo(
    () => options.find(each => each.name === selected),
    [options, selected],
  );

  return (
    <>
      <Listbox disabled={disabled} value={selectedOptions} onChange={handleChange}>
        {({ open }) => (
          <>
            <Listbox.Label className="inline-flex h-5 items-center justify-start gap-1 self-stretch">
              <label
                className={clsMerge(
                  "text-sm font-medium leading-[20px] text-[#343434]",
                  labelClassNames,
                )}
                {...labelRestProps}
              >
                {label}
                <span className="ml-1 text-sm font-medium text-[#87888C]">{inlineHelperText}</span>
              </label>
            </Listbox.Label>

            <div className="relative mt-2">
              <Listbox.Button
                className={clsMerge(
                  "h-10 w-full self-stretch rounded border-none bg-white p-2 text-left ring-1 ring-[#40404020] focus-within:ring-[#2684FF] focus:outline-none focus:ring-[1px] focus:focus-within:ring-[1px]  focus-visible:outline-none",
                  disabled &&
                    "cursor-default bg-[#DCDCDC] text-[#666666] ring-1 ring-[#DCDCDC] focus:ring-[0px]",
                )}
                tabIndex={0}
              >
                <span
                  className={clsMerge(
                    "block truncate text-sm",
                    !selectedOptions?.name && "text-[#87888C]",
                  )}
                >
                  {selectedOptions?.name ?? "Select..."}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronArrowDown
                    aria-hidden="true"
                    className={clsMerge("h-4 w-4 text-[#343434]", disabled && "text-[#666666]")}
                  />
                </span>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={open}
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map(each => (
                    <Listbox.Option
                      className={({ active }) =>
                        clsMerge(
                          active ? "bg-[#F5F5F5]" : "text-[#343434]",
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                        )
                      }
                      key={each.id}
                      value={each}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={clsMerge(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate",
                            )}
                          >
                            {each.name}
                          </span>

                          {selected ? (
                            <span
                              className={
                                "absolute inset-y-0 right-0 flex items-center pr-4 text-[#343434]"
                              }
                            >
                              <CheckIcon aria-hidden="true" className="h-5 w-5" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {Boolean(error) && (
        <p
          className={clsMerge("mt-2 text-xs font-normal text-red-600", errorClassName)}
          {...errorRestProps}
        >
          {error}
        </p>
      )}
    </>
  );
};

export default FormSelectWithLabel;
