import { clsMerge } from "@artifactlabs/shared-react-utils";
import { FunctionComponent } from "react";

import UserProfile from "@/components/Authentication/UserProfile";
import { poppins } from "@/utils/font";

const UserProfileModalView: FunctionComponent = () => {
  return (
    <div
      className={clsMerge(
        "flex min-h-[50%] flex-1 flex-col items-center justify-start overflow-y-auto bg-white",
        "h-[100vh] w-[100vw] px-[15px] py-[40px]",
        "md:h-fit md:max-h-[95vh] md:w-[536px] md:rounded-lg md:bg-[#E6E6E6] md:p-[40px]",
      )}
    >
      <div className="max-w-[536px]">
        {/* Page Title */}
        <div
          className={clsMerge(
            "h-[36px] text-center text-2xl font-medium text-[#343434]",
            poppins.className,
          )}
        >
          Create your profile
        </div>

        {/* Registration Form */}
        <UserProfile className={clsMerge("mt-[40px]", poppins.className)} disabled={false} />
      </div>
    </div>
  );
};

export default UserProfileModalView;
