import { gql } from "@apollo/client";

export const getUserQuery = gql`
  query getUser {
    user {
      orgId
      profile {
        firstName
        lastName
        contactNumber
        entity {
          addressLineOne
          addressLineTwo
          contactNumber
          country
          industry
          jobTitle
          name
        }
      }
    }
  }
`;

export const createUserMutation = gql`
  mutation createUser($profile: UserProfileInput!) {
    user(input: { profile: $profile })
  }
`;
