//
export const default_config = {
  //
  contactEmail: "",
  //
  allowMobileViewOnDev: true,
  //
  licenseLink: `https://${process.env.NEXT_PUBLIC_S3_PUBLIC_ASSETS_BUCKET_HOST_NAME!}/documents/content-licensing/Artevault_Content+Licensing+Agreement.pdf`,

  defaultCurrency: "USD",
};
