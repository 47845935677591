import { default_config } from "./configs/default.config";
import { hkphil_config } from "./configs/hkphil.config";
import { map_config } from "./configs/map.config";
import { platform_config } from "./configs/platform.config";
import { scmp_config } from "./configs/scmp.config";
import { snm_config } from "./configs/snm.config";
import { titanic_config } from "./configs/titanic.config";
import { usn_config } from "./configs/usn.config";
import { ngs_config } from "@/utils/org/configs/ngs.config";
import { szabotage_config } from "@/utils/org/configs/szabotage.config";

export interface IOrganizationConfig {
  contactEmail?: string;
  licenseOptions?: any;
  defaultLicenseDetails?: any;
  allowMobileViewOnDev: boolean;
  licenseLink?: string;
  showLicenseLinkOnMyCollectionPage?: boolean;
  hideCreationDateFilter?: boolean;
  hideAssetTypeFilter?: boolean;
  hideRelevantDatesFilter?:boolean;
  defaultCurrency: string;
  isAddressMandatory?: boolean;
  hideContractInfo?: boolean;
}

//
export const getOrgConfigByTenantId = (tenantId: string = ""): IOrganizationConfig => {
  switch (tenantId) {
    case "scmp":
      return scmp_config;
    case "platform":
      return platform_config;
    case "snm":
      return snm_config;
    case "usn":
      return usn_config;
    case "hkphil":
      return hkphil_config;
    case "map":
      return map_config;
    case "titanic":
      return titanic_config;
    case "ngs":
      return ngs_config;
    case "szabotage":
      return szabotage_config;
    default:
      return default_config;
  }
};
