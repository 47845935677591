import { gql } from "@apollo/client";

export const GET_CHECKOUT_SESSION = gql`
  query GetCheckoutSession($sessionId: String!) {
    getCheckoutSession(sessionId: $sessionId) {
      status
      customerEmail
    }
  }
`;

export const CREATE_CHECKOUT_SESSION_MUTATION = gql`
  mutation CreateCheckoutSession(
    $orderKey: String!
    $unitAmount: Int!
    $currency: String!
    $extIds: [String]!
  ) {
    createCheckoutSession(
      orderKey: $orderKey
      unitAmount: $unitAmount
      currency: $currency
      extIds: $extIds
    ) {
      clientSecret
    }
  }
`;
