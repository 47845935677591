import { useCallback } from "react";
import { useSetRecoilState } from "recoil";

import UIAtom from "@/recoil/UI";

type DRAWER_VIEWS = "CHECKOUT" | "TRANSACTION";

export const useOpenDrawer = () => {
  const setUI = useSetRecoilState(UIAtom);
  return useCallback(
    (view: DRAWER_VIEWS, drawerProps?: Record<string, unknown>) =>
      setUI(state => ({
        ...state,
        drawerView: view,
        displayDrawer: true,
        drawerProps: drawerProps || {},
      })),
    [setUI],
  );
};

export const useCloseDrawer = () => {
  const setUI = useSetRecoilState(UIAtom);
  return () =>
    setUI(state => ({
      ...state,
      displayDrawer: false,
    }));
};

export const useSetDrawerView = () => {
  const setUI = useSetRecoilState(UIAtom);
  return (view: DRAWER_VIEWS, drawerProps?: Record<string, unknown>) =>
    setUI(state => ({
      ...state,
      drawerView: view,
      drawerProps: drawerProps || {},
    }));
};
