import { ReadonlyURLSearchParams } from "next/navigation";

const appendParamsToUrl = (url: string, params: Array<{ key: string; value: string }>) => {
  let baseUrl = url;
  let urlWithParams = baseUrl;

  const searchParams = new URLSearchParams(baseUrl.includes("?") ? baseUrl.split("?")[1] : "");

  params.forEach(({ key, value }) => {
    if (key && value) {
      searchParams.set(key, value);
    }
  });

  urlWithParams = `${baseUrl.split("?")[0]}?${searchParams}`;

  return urlWithParams;
};

const createUrl = (pathname: string, params: URLSearchParams | ReadonlyURLSearchParams) => {
  const paramsString = params.toString();
  const queryString = `${paramsString.length ? "?" : ""}${paramsString}`;

  return `${pathname}${queryString}`;
};

const routes = {
  home: "/",
  gallery: "/gallery",
  search: (
    page: number,
    mediaType: string,
    params?: URLSearchParams | ReadonlyURLSearchParams,
    withDefaultParams: boolean = false,
  ) => {
    const searchParams = new URLSearchParams(params ?? undefined);
    if (withDefaultParams) {
      searchParams.set("sort", "0");
    }
    const pathname = `/search/${page}/${mediaType}`;
    return createUrl(pathname, searchParams);
  },
  artwork(id: string) {
    return `/artwork/${id}`;
  },
  profile() {
    return "/my-profile";
  },
  request(id: string, licenseType?: string) {
    if (licenseType) {
      return `/request/${id}?type=${licenseType}`;
    }
    return `/request/${id}`;
  },
  myRequests({ id, tab }: { id?: string; tab?: string }) {
    let url = `/my-requests`;

    if (id) {
      url = `${url}/${id}`;
    }
    if (tab) {
      return appendParamsToUrl(url, [{ key: "tab", value: tab }]);
    }
    return url;
  },
  myPurchases({ id, tab }: { id?: string; tab?: string }) {
    let url = `/my-purchases`;

    if (id) {
      url = `${url}/${id}`;
    }
    if (tab) {
      return appendParamsToUrl(url, [{ key: "tab", value: tab }]);
    }
    return url;
  },
  myCollection(id?: string) {
    if (id) {
      return `/my-collection/artwork/${id}`;
    } else {
      return `/my-collection?tab=active`;
    }
  },
  vault: "/vault",
};

export { routes };
