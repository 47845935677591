export const API_GQL_URL = process.env.NEXT_PUBLIC_GQL_ENDPOINT!;

export const industriesOptions = [
  { id: "industries-1", name: "Academic / educational" },
  { id: "industries-2", name: "Broadcast & Entertainment" },
  { id: "industries-3", name: "Commercial & Corporate" },
  { id: "industries-4", name: "Editorial Publishing" },
  { id: "industries-5", name: "Newspaper and Magazine" },
  { id: "industries-6", name: "Licensing Agent" },
  { id: "industries-7", name: "Non Profit & Heritage" },
  { id: "industries-8", name: "Web/Multimedia" },
  { id: "industries-9", name: "Other" },
];

export const languagesISO639 = [
  {
    id: "ab",
    label: "Abkhaz",
    value: "Abkhaz",
  },
  {
    id: "aa",
    label: "Afar",
    value: "Afar",
  },
  {
    id: "af",
    label: "Afrikaans",
    value: "Afrikaans",
  },
  {
    id: "ak",
    label: "Akan",
    value: "Akan",
  },
  {
    id: "sq",
    label: "Albanian",
    value: "Albanian",
  },
  {
    id: "am",
    label: "Amharic",
    value: "Amharic",
  },
  {
    id: "ar",
    label: "Arabic",
    value: "Arabic",
  },
  {
    id: "an",
    label: "Aragonese",
    value: "Aragonese",
  },
  {
    id: "hy",
    label: "Armenian",
    value: "Armenian",
  },
  {
    id: "as",
    label: "Assamese",
    value: "Assamese",
  },
  {
    id: "av",
    label: "Avaric",
    value: "Avaric",
  },
  {
    id: "ae",
    label: "Avestan",
    value: "Avestan",
  },
  {
    id: "ay",
    label: "Aymara",
    value: "Aymara",
  },
  {
    id: "az",
    label: "Azerbaijani",
    value: "Azerbaijani",
  },
  {
    id: "bm",
    label: "Bambara",
    value: "Bambara",
  },
  {
    id: "ba",
    label: "Bashkir",
    value: "Bashkir",
  },
  {
    id: "eu",
    label: "Basque",
    value: "Basque",
  },
  {
    id: "be",
    label: "Belarusian",
    value: "Belarusian",
  },
  {
    id: "bn",
    label: "Bengali; Bangla",
    value: "Bengali; Bangla",
  },
  {
    id: "bh",
    label: "Bihari",
    value: "Bihari",
  },
  {
    id: "bi",
    label: "Bislama",
    value: "Bislama",
  },
  {
    id: "bs",
    label: "Bosnian",
    value: "Bosnian",
  },
  {
    id: "br",
    label: "Breton",
    value: "Breton",
  },
  {
    id: "bg",
    label: "Bulgarian",
    value: "Bulgarian",
  },
  {
    id: "my",
    label: "Burmese",
    value: "Burmese",
  },
  {
    id: "ca",
    label: "Catalan; Valencian",
    value: "Catalan; Valencian",
  },
  {
    id: "ch",
    label: "Chamorro",
    value: "Chamorro",
  },
  {
    id: "ce",
    label: "Chechen",
    value: "Chechen",
  },
  {
    id: "ny",
    label: "Chichewa; Chewa; Nyanja",
    value: "Chichewa; Chewa; Nyanja",
  },
  {
    id: "zh",
    label: "Chinese",
    value: "Chinese",
  },
  {
    id: "cv",
    label: "Chuvash",
    value: "Chuvash",
  },
  {
    id: "kw",
    label: "Cornish",
    value: "Cornish",
  },
  {
    id: "co",
    label: "Corsican",
    value: "Corsican",
  },
  {
    id: "cr",
    label: "Cree",
    value: "Cree",
  },
  {
    id: "hr",
    label: "Croatian",
    value: "Croatian",
  },
  {
    id: "cs",
    label: "Czech",
    value: "Czech",
  },
  {
    id: "da",
    label: "Danish",
    value: "Danish",
  },
  {
    id: "dv",
    label: "Divehi; Dhivehi; Maldivian;",
    value: "Divehi; Dhivehi; Maldivian;",
  },
  {
    id: "nl",
    label: "Dutch",
    value: "Dutch",
  },
  {
    id: "dz",
    label: "Dzongkha",
    value: "Dzongkha",
  },
  {
    id: "en",
    label: "English",
    value: "English",
  },
  {
    id: "eo",
    label: "Esperanto",
    value: "Esperanto",
  },
  {
    id: "et",
    label: "Estonian",
    value: "Estonian",
  },
  {
    id: "ee",
    label: "Ewe",
    value: "Ewe",
  },
  {
    id: "fo",
    label: "Faroese",
    value: "Faroese",
  },
  {
    id: "fj",
    label: "Fijian",
    value: "Fijian",
  },
  {
    id: "fi",
    label: "Finnish",
    value: "Finnish",
  },
  {
    id: "fr",
    label: "French",
    value: "French",
  },
  {
    id: "ff",
    label: "Fula; Fulah; Pulaar; Pular",
    value: "Fula; Fulah; Pulaar; Pular",
  },
  {
    id: "gl",
    label: "Galician",
    value: "Galician",
  },
  {
    id: "ka",
    label: "Georgian",
    value: "Georgian",
  },
  {
    id: "de",
    label: "German",
    value: "German",
  },
  {
    id: "el",
    label: "Greek, Modern",
    value: "Greek, Modern",
  },
  {
    id: "gn",
    label: "GuaranÃ­",
    value: "GuaranÃ­",
  },
  {
    id: "gu",
    label: "Gujarati",
    value: "Gujarati",
  },
  {
    id: "ht",
    label: "Haitian; Haitian Creole",
    value: "Haitian; Haitian Creole",
  },
  {
    id: "ha",
    label: "Hausa",
    value: "Hausa",
  },
  {
    id: "he",
    label: "Hebrew (modern)",
    value: "Hebrew (modern)",
  },
  {
    id: "hz",
    label: "Herero",
    value: "Herero",
  },
  {
    id: "hi",
    label: "Hindi",
    value: "Hindi",
  },
  {
    id: "ho",
    label: "Hiri Motu",
    value: "Hiri Motu",
  },
  {
    id: "hu",
    label: "Hungarian",
    value: "Hungarian",
  },
  {
    id: "ia",
    label: "Interlingua",
    value: "Interlingua",
  },
  {
    id: "id",
    label: "Indonesian",
    value: "Indonesian",
  },
  {
    id: "ie",
    label: "Interlingue",
    value: "Interlingue",
  },
  {
    id: "ga",
    label: "Irish",
    value: "Irish",
  },
  {
    id: "ig",
    label: "Igbo",
    value: "Igbo",
  },
  {
    id: "ik",
    label: "Inupiaq",
    value: "Inupiaq",
  },
  {
    id: "io",
    label: "Ido",
    value: "Ido",
  },
  {
    id: "is",
    label: "Icelandic",
    value: "Icelandic",
  },
  {
    id: "it",
    label: "Italian",
    value: "Italian",
  },
  {
    id: "iu",
    label: "Inuktitut",
    value: "Inuktitut",
  },
  {
    id: "ja",
    label: "Japanese",
    value: "Japanese",
  },
  {
    id: "jv",
    label: "Javanese",
    value: "Javanese",
  },
  {
    id: "kl",
    label: "Kalaallisut, Greenlandic",
    value: "Kalaallisut, Greenlandic",
  },
  {
    id: "kn",
    label: "Kannada",
    value: "Kannada",
  },
  {
    id: "kr",
    label: "Kanuri",
    value: "Kanuri",
  },
  {
    id: "ks",
    label: "Kashmiri",
    value: "Kashmiri",
  },
  {
    id: "kk",
    label: "Kazakh",
    value: "Kazakh",
  },
  {
    id: "km",
    label: "Khmer",
    value: "Khmer",
  },
  {
    id: "ki",
    label: "Kikuyu, Gikuyu",
    value: "Kikuyu, Gikuyu",
  },
  {
    id: "rw",
    label: "Kinyarwanda",
    value: "Kinyarwanda",
  },
  {
    id: "ky",
    label: "Kyrgyz",
    value: "Kyrgyz",
  },
  {
    id: "kv",
    label: "Komi",
    value: "Komi",
  },
  {
    id: "kg",
    label: "Kongo",
    value: "Kongo",
  },
  {
    id: "ko",
    label: "Korean",
    value: "Korean",
  },
  {
    id: "ku",
    label: "Kurdish",
    value: "Kurdish",
  },
  {
    id: "kj",
    label: "Kwanyama, Kuanyama",
    value: "Kwanyama, Kuanyama",
  },
  {
    id: "la",
    label: "Latin",
    value: "Latin",
  },
  {
    id: "lb",
    label: "Luxembourgish, Letzeburgesch",
    value: "Luxembourgish, Letzeburgesch",
  },
  {
    id: "lg",
    label: "Ganda",
    value: "Ganda",
  },
  {
    id: "li",
    label: "Limburgish, Limburgan, Limburger",
    value: "Limburgish, Limburgan, Limburger",
  },
  {
    id: "ln",
    label: "Lingala",
    value: "Lingala",
  },
  {
    id: "lo",
    label: "Lao",
    value: "Lao",
  },
  {
    id: "lt",
    label: "Lithuanian",
    value: "Lithuanian",
  },
  {
    id: "lu",
    label: "Luba-Katanga",
    value: "Luba-Katanga",
  },
  {
    id: "lv",
    label: "Latvian",
    value: "Latvian",
  },
  {
    id: "gv",
    label: "Manx",
    value: "Manx",
  },
  {
    id: "mk",
    label: "Macedonian",
    value: "Macedonian",
  },
  {
    id: "mg",
    label: "Malagasy",
    value: "Malagasy",
  },
  {
    id: "ms",
    label: "Malay",
    value: "Malay",
  },
  {
    id: "ml",
    label: "Malayalam",
    value: "Malayalam",
  },
  {
    id: "mt",
    label: "Maltese",
    value: "Maltese",
  },
  {
    id: "mi",
    label: "MÄori",
    value: "MÄori",
  },
  {
    id: "mr",
    label: "Marathi (MarÄá¹­hÄ«)",
    value: "Marathi (MarÄá¹­hÄ«)",
  },
  {
    id: "mh",
    label: "Marshallese",
    value: "Marshallese",
  },
  {
    id: "mn",
    label: "Mongolian",
    value: "Mongolian",
  },
  {
    id: "na",
    label: "Nauru",
    value: "Nauru",
  },
  {
    id: "nv",
    label: "Navajo, Navaho",
    value: "Navajo, Navaho",
  },
  {
    id: "nb",
    label: "Norwegian BokmÃ¥l",
    value: "Norwegian BokmÃ¥l",
  },
  {
    id: "nd",
    label: "North Ndebele",
    value: "North Ndebele",
  },
  {
    id: "ne",
    label: "Nepali",
    value: "Nepali",
  },
  {
    id: "ng",
    label: "Ndonga",
    value: "Ndonga",
  },
  {
    id: "nn",
    label: "Norwegian Nynorsk",
    value: "Norwegian Nynorsk",
  },
  {
    id: "no",
    label: "Norwegian",
    value: "Norwegian",
  },
  {
    id: "ii",
    label: "Nuosu",
    value: "Nuosu",
  },
  {
    id: "nr",
    label: "South Ndebele",
    value: "South Ndebele",
  },
  {
    id: "oc",
    label: "Occitan",
    value: "Occitan",
  },
  {
    id: "oj",
    label: "Ojibwe, Ojibwa",
    value: "Ojibwe, Ojibwa",
  },
  {
    id: "cu",
    label: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    value: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
  },
  {
    id: "om",
    label: "Oromo",
    value: "Oromo",
  },
  {
    id: "or",
    label: "Oriya",
    value: "Oriya",
  },
  {
    id: "os",
    label: "Ossetian, Ossetic",
    value: "Ossetian, Ossetic",
  },
  {
    id: "pa",
    label: "Panjabi, Punjabi",
    value: "Panjabi, Punjabi",
  },
  {
    id: "pi",
    label: "PÄli",
    value: "PÄli",
  },
  {
    id: "fa",
    label: "Persian (Farsi)",
    value: "Persian (Farsi)",
  },
  {
    id: "pl",
    label: "Polish",
    value: "Polish",
  },
  {
    id: "ps",
    label: "Pashto, Pushto",
    value: "Pashto, Pushto",
  },
  {
    id: "pt",
    label: "Portuguese",
    value: "Portuguese",
  },
  {
    id: "qu",
    label: "Quechua",
    value: "Quechua",
  },
  {
    id: "rm",
    label: "Romansh",
    value: "Romansh",
  },
  {
    id: "rn",
    label: "Kirundi",
    value: "Kirundi",
  },
  {
    id: "ro",
    label: "Romanian",
    value: "Romanian",
  },
  {
    id: "ru",
    label: "Russian",
    value: "Russian",
  },
  {
    id: "sa",
    label: "Sanskrit (Saá¹ská¹›ta)",
    value: "Sanskrit (Saá¹ská¹›ta)",
  },
  {
    id: "sc",
    label: "Sardinian",
    value: "Sardinian",
  },
  {
    id: "sd",
    label: "Sindhi",
    value: "Sindhi",
  },
  {
    id: "se",
    label: "Northern Sami",
    value: "Northern Sami",
  },
  {
    id: "sm",
    label: "Samoan",
    value: "Samoan",
  },
  {
    id: "sg",
    label: "Sango",
    value: "Sango",
  },
  {
    id: "sr",
    label: "Serbian",
    value: "Serbian",
  },
  {
    id: "gd",
    label: "Scottish Gaelic; Gaelic",
    value: "Scottish Gaelic; Gaelic",
  },
  {
    id: "sn",
    label: "Shona",
    value: "Shona",
  },
  {
    id: "si",
    label: "Sinhala, Sinhalese",
    value: "Sinhala, Sinhalese",
  },
  {
    id: "sk",
    label: "Slovak",
    value: "Slovak",
  },
  {
    id: "sl",
    label: "Slovene",
    value: "Slovene",
  },
  {
    id: "so",
    label: "Somali",
    value: "Somali",
  },
  {
    id: "st",
    label: "Southern Sotho",
    value: "Southern Sotho",
  },
  {
    id: "es",
    label: "Spanish",
    value: "Spanish",
  },
  {
    id: "su",
    label: "Sundanese",
    value: "Sundanese",
  },
  {
    id: "sw",
    label: "Swahili",
    value: "Swahili",
  },
  {
    id: "ss",
    label: "Swati",
    value: "Swati",
  },
  {
    id: "sv",
    label: "Swedish",
    value: "Swedish",
  },
  {
    id: "ta",
    label: "Tamil",
    value: "Tamil",
  },
  {
    id: "te",
    label: "Telugu",
    value: "Telugu",
  },
  {
    id: "tg",
    label: "Tajik",
    value: "Tajik",
  },
  {
    id: "th",
    label: "Thai",
    value: "Thai",
  },
  {
    id: "ti",
    label: "Tigrinya",
    value: "Tigrinya",
  },
  {
    id: "bo",
    label: "Tibetan Standard, Tibetan, Central",
    value: "Tibetan Standard, Tibetan, Central",
  },
  {
    id: "tk",
    label: "Turkmen",
    value: "Turkmen",
  },
  {
    id: "tl",
    label: "Tagalog",
    value: "Tagalog",
  },
  {
    id: "tn",
    label: "Tswana",
    value: "Tswana",
  },
  {
    id: "to",
    label: "Tonga (Tonga Islands)",
    value: "Tonga (Tonga Islands)",
  },
  {
    id: "tr",
    label: "Turkish",
    value: "Turkish",
  },
  {
    id: "ts",
    label: "Tsonga",
    value: "Tsonga",
  },
  {
    id: "tt",
    label: "Tatar",
    value: "Tatar",
  },
  {
    id: "tw",
    label: "Twi",
    value: "Twi",
  },
  {
    id: "ty",
    label: "Tahitian",
    value: "Tahitian",
  },
  {
    id: "ug",
    label: "Uyghur, Uighur",
    value: "Uyghur, Uighur",
  },
  {
    id: "uk",
    label: "Ukrainian",
    value: "Ukrainian",
  },
  {
    id: "ur",
    label: "Urdu",
    value: "Urdu",
  },
  {
    id: "uz",
    label: "Uzbek",
    value: "Uzbek",
  },
  {
    id: "ve",
    label: "Venda",
    value: "Venda",
  },
  {
    id: "vi",
    label: "Vietnamese",
    value: "Vietnamese",
  },
  {
    id: "vo",
    label: "VolapÃ¼k",
    value: "VolapÃ¼k",
  },
  {
    id: "wa",
    label: "Walloon",
    value: "Walloon",
  },
  {
    id: "cy",
    label: "Welsh",
    value: "Welsh",
  },
  {
    id: "wo",
    label: "Wolof",
    value: "Wolof",
  },
  {
    id: "fy",
    label: "Western Frisian",
    value: "Western Frisian",
  },
  {
    id: "xh",
    label: "Xhosa",
    value: "Xhosa",
  },
  {
    id: "yi",
    label: "Yiddish",
    value: "Yiddish",
  },
  {
    id: "yo",
    label: "Yoruba",
    value: "Yoruba",
  },
  {
    id: "za",
    label: "Zhuang, Chuang",
    value: "Zhuang, Chuang",
  },
  {
    id: "zu",
    label: "Zulu",
    value: "Zulu",
  },
];

export const countriesISO3166 = [
  { id: "AF", name: "Afghanistan" },
  { id: "AX", name: "Åland Islands" },
  { id: "AL", name: "Albania" },
  { id: "DZ", name: "Algeria" },
  { id: "AS", name: "American Samoa" },
  { id: "AD", name: "Andorra" },
  { id: "AO", name: "Angola" },
  { id: "AI", name: "Anguilla" },
  { id: "AQ", name: "Antarctica" },
  { id: "AG", name: "Antigua and Barbuda" },
  { id: "AR", name: "Argentina" },
  { id: "AM", name: "Armenia" },
  { id: "AW", name: "Aruba" },
  { id: "AU", name: "Australia" },
  { id: "AT", name: "Austria" },
  { id: "AZ", name: "Azerbaijan" },
  { id: "BS", name: "Bahamas" },
  { id: "BH", name: "Bahrain" },
  { id: "BD", name: "Bangladesh" },
  { id: "BB", name: "Barbados" },
  { id: "BY", name: "Belarus" },
  { id: "BE", name: "Belgium" },
  { id: "BZ", name: "Belize" },
  { id: "BJ", name: "Benin" },
  { id: "BM", name: "Bermuda" },
  { id: "BT", name: "Bhutan" },
  { id: "BO", name: "Bolivia (Plurinational State of)" },
  { id: "BQ", name: "Bonaire, Sint Eustatius and Saba" },
  { id: "BA", name: "Bosnia and Herzegovina" },
  { id: "BW", name: "Botswana" },
  { id: "BV", name: "Bouvet Island" },
  { id: "BR", name: "Brazil" },
  { id: "IO", name: "British Indian Ocean Territory" },
  { id: "BN", name: "Brunei Darussalam" },
  { id: "BG", name: "Bulgaria" },
  { id: "BF", name: "Burkina Faso" },
  { id: "BI", name: "Burundi" },
  { id: "CV", name: "Cabo Verde" },
  { id: "KH", name: "Cambodia" },
  { id: "CM", name: "Cameroon" },
  { id: "CA", name: "Canada" },
  { id: "KY", name: "Cayman Islands" },
  { id: "CF", name: "Central African Republic" },
  { id: "TD", name: "Chad" },
  { id: "CL", name: "Chile" },
  { id: "CN", name: "China" },
  { id: "CX", name: "Christmas Island" },
  { id: "CC", name: "Cocos (Keeling) Islands" },
  { id: "CO", name: "Colombia" },
  { id: "KM", name: "Comoros" },
  { id: "CG", name: "Congo" },
  { id: "CD", name: "Congo (Democratic Republic of the)" },
  { id: "CK", name: "Cook Islands" },
  { id: "CR", name: "Costa Rica" },
  { id: "CI", name: "Côte d'Ivoire" },
  { id: "HR", name: "Croatia" },
  { id: "CU", name: "Cuba" },
  { id: "CW", name: "Curaçao" },
  { id: "CY", name: "Cyprus" },
  { id: "CZ", name: "Czech Republic" },
  { id: "DK", name: "Denmark" },
  { id: "DJ", name: "Djibouti" },
  { id: "DM", name: "Dominica" },
  { id: "DO", name: "Dominican Republic" },
  { id: "EC", name: "Ecuador" },
  { id: "EG", name: "Egypt" },
  { id: "SV", name: "El Salvador" },
  { id: "GQ", name: "Equatorial Guinea" },
  { id: "ER", name: "Eritrea" },
  { id: "EE", name: "Estonia" },
  { id: "ET", name: "Ethiopia" },
  { id: "FK", name: "Falkland Islands (Malvinas)" },
  { id: "FO", name: "Faroe Islands" },
  { id: "FJ", name: "Fiji (Republic of)" },
  { id: "FI", name: "Finland" },
  { id: "FR", name: "France" },
  { id: "GF", name: "French Guiana" },
  { id: "PF", name: "French Polynesia" },
  { id: "TF", name: "French Southern Territories" },
  { id: "GA", name: "Gabon" },
  { id: "GM", name: "Gambia" },
  { id: "GE", name: "Georgia" },
  { id: "DE", name: "Germany" },
  { id: "GH", name: "Ghana" },
  { id: "GI", name: "Gibraltar" },
  { id: "GR", name: "Greece" },
  { id: "GL", name: "Greenland" },
  { id: "GD", name: "Grenada" },
  { id: "GP", name: "Guadeloupe" },
  { id: "GU", name: "Guam" },
  { id: "GT", name: "Guatemala" },
  { id: "GG", name: "Guernsey" },
  { id: "GN", name: "Guinea" },
  { id: "GW", name: "Guinea-Bissau" },
  { id: "GY", name: "Guyana" },
  { id: "HT", name: "Haiti" },
  { id: "HM", name: "Heard Island and McDonald Islands" },
  { id: "VA", name: "Holy See" },
  { id: "HN", name: "Honduras" },
  { id: "HK", name: "Hong Kong" },
  { id: "HU", name: "Hungary" },
  { id: "IS", name: "Iceland" },
  { id: "IN", name: "India" },
  { id: "ID", name: "Indonesia" },
  { id: "IR", name: "Iran (Islamic Republic of)" },
  { id: "IQ", name: "Iraq" },
  { id: "IE", name: "Ireland" },
  { id: "IM", name: "Isle of Man" },
  { id: "IL", name: "Israel" },
  { id: "IT", name: "Italy" },
  { id: "JM", name: "Jamaica" },
  { id: "JP", name: "Japan" },
  { id: "JE", name: "Jersey" },
  { id: "JO", name: "Jordan" },
  { id: "KZ", name: "Kazakhstan" },
  { id: "KE", name: "Kenya" },
  { id: "KI", name: "Kiribati" },
  { id: "KP", name: "Korea (Democratic People's Republic of)" },
  { id: "KR", name: "Korea (Republic of)" },
  { id: "KW", name: "Kuwait" },
  { id: "KG", name: "Kyrgyzstan" },
  { id: "LA", name: "Lao People's Democratic Republic" },
  { id: "LV", name: "Latvia" },
  { id: "LB", name: "Lebanon" },
  { id: "LS", name: "Lesotho" },
  { id: "LR", name: "Liberia" },
  { id: "LY", name: "Libya" },
  { id: "LI", name: "Liechtenstein" },
  { id: "LT", name: "Lithuania" },
  { id: "LU", name: "Luxembourg" },
  { id: "MO", name: "Macao" },
  { id: "MK", name: "Macedonia (the former Yugoslav Republic of)" },
  { id: "MG", name: "Madagascar" },
  { id: "MW", name: "Malawi" },
  { id: "MY", name: "Malaysia" },
  { id: "MV", name: "Maldives" },
  { id: "ML", name: "Mali" },
  { id: "MT", name: "Malta" },
  { id: "MH", name: "Marshall Islands" },
  { id: "MQ", name: "Martinique" },
  { id: "MR", name: "Mauritania" },
  { id: "MU", name: "Mauritius" },
  { id: "YT", name: "Mayotte" },
  { id: "MX", name: "Mexico" },
  { id: "FM", name: "Micronesia (Federated States of)" },
  { id: "MD", name: "Moldova (Republic of)" },
  { id: "MC", name: "Monaco" },
  { id: "MN", name: "Mongolia" },
  { id: "ME", name: "Montenegro" },
  { id: "MS", name: "Montserrat" },
  { id: "MA", name: "Morocco" },
  { id: "MZ", name: "Mozambique" },
  { id: "MM", name: "Myanmar" },
  { id: "NA", name: "Namibia" },
  { id: "NR", name: "Nauru" },
  { id: "NP", name: "Nepal" },
  { id: "NL", name: "Netherlands" },
  { id: "NC", name: "New Caledonia" },
  { id: "NZ", name: "New Zealand" },
  { id: "NI", name: "Nicaragua" },
  { id: "NE", name: "Niger" },
  { id: "NG", name: "Nigeria" },
  { id: "NU", name: "Niue" },
  { id: "NF", name: "Norfolk Island" },
  { id: "MP", name: "Northern Mariana Islands" },
  { id: "NO", name: "Norway" },
  { id: "OM", name: "Oman" },
  { id: "PK", name: "Pakistan" },
  { id: "PW", name: "Palau" },
  { id: "PS", name: "Palestine, State of" },
  { id: "PA", name: "Panama" },
  { id: "PG", name: "Papua New Guinea" },
  { id: "PY", name: "Paraguay" },
  { id: "PE", name: "Peru" },
  { id: "PH", name: "Philippines" },
  { id: "PN", name: "Pitcairn" },
  { id: "PL", name: "Poland" },
  { id: "PT", name: "Portugal" },
  { id: "PR", name: "Puerto Rico" },
  { id: "QA", name: "Qatar" },
  { id: "RE", name: "Réunion" },
  { id: "RO", name: "Romania" },
  { id: "RU", name: "Russian Federation" },
  { id: "RW", name: "Rwanda" },
  { id: "BL", name: "Saint Barthélemy" },
  { id: "SH", name: "Saint Helena, Ascension and Tristan da Cunha" },
  { id: "KN", name: "Saint Kitts and Nevis" },
  { id: "LC", name: "Saint Lucia" },
  { id: "MF", name: "Saint Martin (French part)" },
  { id: "PM", name: "Saint Pierre and Miquelon" },
  { id: "VC", name: "Saint Vincent and the Grenadines" },
  { id: "WS", name: "Samoa" },
  { id: "SM", name: "San Marino" },
  { id: "ST", name: "Sao Tome and Principe" },
  { id: "SA", name: "Saudi Arabia" },
  { id: "SN", name: "Senegal" },
  { id: "RS", name: "Serbia" },
  { id: "SC", name: "Seychelles" },
  { id: "SL", name: "Sierra Leone" },
  { id: "SG", name: "Singapore" },
  { id: "SX", name: "Sint Maarten (Dutch part)" },
  { id: "SK", name: "Slovakia" },
  { id: "SI", name: "Slovenia" },
  { id: "SB", name: "Solomon Islands" },
  { id: "SO", name: "Somalia" },
  { id: "ZA", name: "South Africa" },
  { id: "GS", name: "South Georgia and the South Sandwich Islands" },
  { id: "SS", name: "South Sudan" },
  { id: "ES", name: "Spain" },
  { id: "LK", name: "Sri Lanka" },
  { id: "SD", name: "Sudan" },
  { id: "SR", name: "Suriname" },
  { id: "SJ", name: "Svalbard and Jan Mayen" },
  { id: "SZ", name: "Swaziland" },
  { id: "SE", name: "Sweden" },
  { id: "CH", name: "Switzerland" },
  { id: "SY", name: "Syrian Arab Republic" },
  { id: "TW", name: "Taiwan, Province of China" },
  { id: "TJ", name: "Tajikistan" },
  { id: "TZ", name: "Tanzania, United Republic of" },
  { id: "TH", name: "Thailand" },
  { id: "TL", name: "Timor-Leste" },
  { id: "TG", name: "Togo" },
  { id: "TK", name: "Tokelau" },
  { id: "TO", name: "Tonga" },
  { id: "TT", name: "Trinidad and Tobago" },
  { id: "TN", name: "Tunisia" },
  { id: "TR", name: "Turkey" },
  { id: "TM", name: "Turkmenistan" },
  { id: "TC", name: "Turks and Caicos Islands" },
  { id: "TV", name: "Tuvalu" },
  { id: "UG", name: "Uganda" },
  { id: "UA", name: "Ukraine" },
  { id: "AE", name: "United Arab Emirates" },
  { id: "GB", name: "United Kingdom of Great Britain and Northern Ireland" },
  { id: "US", name: "United States of America" },
  { id: "UM", name: "United States Minor Outlying Islands" },
  { id: "UY", name: "Uruguay" },
  { id: "UZ", name: "Uzbekistan" },
  { id: "VU", name: "Vanuatu" },
  { id: "VE", name: "Venezuela (Bolivarian Republic of)" },
  { id: "VN", name: "Vietnam" },
  { id: "VG", name: "Virgin Islands (British)" },
  { id: "VI", name: "Virgin Islands (U.S.)" },
  { id: "WF", name: "Wallis and Futuna" },
  { id: "EH", name: "Western Sahara" },
  { id: "YE", name: "Yemen" },
  { id: "ZM", name: "Zambia" },
  { id: "ZW", name: "Zimbabwe" },
];
