/* eslint-disable react/no-unescaped-entities */
import { gql, useMutation } from "@apollo/client";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import { motion } from "framer-motion";
import { useRouter } from "next/navigation";
import { FunctionComponent, useEffect, useRef } from "react";
import { useStep } from "usehooks-ts";

import {
  CompleteMultiRequestsProcessingMutation,
  CompleteMultiRequestsProcessingMutationVariables,
} from "@/gql/graphql";
import { useCloseModal } from "@/hooks/useModal";

interface RequestPaymentModalViewProps {
  extIds: string[];
}

const completeMultiRequestsProcessing = gql`
  mutation completeMultiRequestsProcessing($extIds: [String!]!) {
    completeMultiRequestsProcessing(extIds: $extIds) {
      extId
      status
    }
  }
`;

const RequestPaymentModalView: FunctionComponent<RequestPaymentModalViewProps> = ({ extIds }) => {
  const router = useRouter();
  const closeModal = useCloseModal();
  const [currentStep, { setStep }] = useStep(2);

  const [completeApproval, { loading, called }] = useMutation<
    CompleteMultiRequestsProcessingMutation,
    CompleteMultiRequestsProcessingMutationVariables
  >(completeMultiRequestsProcessing);

  const loadingRef = useRef(loading);
  const calledRef = useRef(called);
  const extIdsRef = useRef(extIds);

  useEffect(() => {
    loadingRef.current = loading;
    calledRef.current = called;
    extIdsRef.current = extIds;
  }, [loading, called, extIds]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      // Use refs to access the most current values
      if (!loadingRef.current && !calledRef.current) {
        await completeApproval({ variables: { extIds: extIdsRef.current } });
        setStep(2);
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [completeApproval, setStep]);

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-white font-poppins text-[#343434] antialiased">
      <div className="absolute top-[20vh] inline-flex items-center justify-center gap-6">
        <div className="inline-flex w-fit flex-col items-center justify-center gap-4 rounded-[100px]">
          <div className="inline-flex items-center justify-center gap-4">
            <div
              className={clsMerge(
                "flex h-8 w-8 items-center justify-center rounded-[100px]",
                currentStep >= 1 && "bg-[#45BE76]",
                currentStep < 1 && "ring-2 ring-inset ring-zinc-500",
              )}
            >
              <svg
                className={clsMerge(
                  "h-6 w-6 stroke-white stroke-[3px]",
                  currentStep < 1 && "hidden",
                )}
                fill="none"
                stroke="currentColor"
                strokeWidth={1.5}
                viewBox="0 0 24 24"
              >
                <path d="M4.5 12.75l6 6 9-13.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
          <div className="absolute -bottom-8 w-max text-center text-xs font-medium text-neutral-700">
            Payment processed
          </div>
        </div>
        <div className="h-0.5 w-[120px] bg-green-400 " />
        <div className="inline-flex w-8 flex-col items-center justify-center gap-4 rounded-[100px]">
          <div className="inline-flex items-center justify-center gap-4">
            <div
              className={clsMerge(
                "flex h-8 w-8 items-center justify-center rounded-[100px]",
                currentStep >= 2 && "bg-[#45BE76]",
                currentStep < 2 && "ring-2 ring-inset ring-zinc-500",
              )}
            >
              <svg
                className={clsMerge(
                  "h-6 w-6 stroke-white stroke-[3px]",
                  currentStep < 2 && "hidden",
                )}
                fill="none"
                stroke="currentColor"
                strokeWidth={1.5}
                viewBox="0 0 24 24"
              >
                <path d="M4.5 12.75l6 6 9-13.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
          <div className="absolute -bottom-8 w-max text-center text-xs font-medium text-neutral-700">
            {currentStep < 2 ? "Delivering item(s)" : "Item(s) delivered"}
          </div>
        </div>
      </div>
      {currentStep === 1 && (
        <motion.div
          animate={{ opacity: 1 }}
          className="relative w-[424px] bg-white"
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="inline-flex h-[103px] flex-col items-center justify-start gap-4">
            <div className="self-stretch text-center text-base font-bold leading-normal">
              Purchase in progress
            </div>
            <div className="self-stretch text-center text-sm font-medium leading-normal">
              We're processing your payment. Please do not refresh the page or exit out of the
              window during this process.
            </div>
          </div>
        </motion.div>
      )}
      {currentStep === 2 && (
        <motion.div
          animate={{ opacity: 1 }}
          className="relative mt-20 w-[424px] bg-white"
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="inline-flex w-full flex-col items-center justify-start gap-4">
            <div className="self-stretch text-center text-base font-bold leading-normal">
              Purchase completed
            </div>
            <div className="self-stretch text-center text-sm font-medium leading-normal">
              Congrats! The artwork(s) is now available in My Collection.
            </div>
            <button
              className="mt-2 flex h-10 items-center justify-center rounded-[4px] bg-[#1C2442] px-4 py-4 text-sm font-medium leading-[20px] text-white hover:bg-[#35447d]"
              type="button"
              onClick={() => {
                closeModal();
                router.push("/my-collection?tab=active");
              }}
            >
              Continue
            </button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default RequestPaymentModalView;
