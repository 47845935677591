import { clsMerge } from "@artifactlabs/shared-react-utils";
import { FunctionComponent, HTMLAttributes, LabelHTMLAttributes } from "react";

import { poppins } from "@/utils/font";

interface FormInputWithLabelProps {
  label: string;
  inlineHelperText?: string;
  error?: string;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  inputProps?: any;
  errorProps?: HTMLAttributes<HTMLDivElement>;
}

const FormInputWithLabel: FunctionComponent<FormInputWithLabelProps> = ({
  label,
  inlineHelperText,
  error,
  labelProps,
  inputProps,
  errorProps,
}) => {
  const { className: labelClassNames, ...labelRestProps } = labelProps ?? {};
  const { className: inputClassName, ...inputRestProps } = inputProps ?? {};
  const { className: errorClassName, ...errorRestProps } = errorProps ?? {};

  return (
    <div className="inline-flex w-full flex-1 shrink grow basis-0 flex-col items-start justify-start gap-1">
      <div className="inline-flex h-5 items-center justify-start gap-1 self-stretch">
        <label
          className={clsMerge(
            "text-sm font-medium leading-[20px] text-[#343434]",
            labelClassNames,
            poppins.className,
          )}
          {...labelRestProps}
        >
          {label}
        </label>
        <span className="ml-1 text-sm font-medium text-[#87888C]">{inlineHelperText}</span>
      </div>
      <div className="relative w-full">
        <input
          className={clsMerge(
            "h-10 w-full self-stretch rounded bg-white p-2 focus-within:outline-none focus-within:ring-1 focus-within:ring-[#2684FF] focus:ring-[#2684FF]",
            "placeholder:text-sm placeholder:leading-[14px] placeholder:text-[#87888C]",
            "ring-1 ring-[#DCDCDC] disabled:bg-[#DCDCDC] disabled:text-[#666666]",
            Boolean(error) && "ring-1 ring-[#E51921]",
            inputClassName,
            poppins.className,
          )}
          {...inputRestProps}
        />
      </div>
      {Boolean(error) && (
        <p
          className={clsMerge("mt-2 text-xs font-normal text-red-600", errorClassName)}
          {...errorRestProps}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default FormInputWithLabel;
