import { useLazyQuery } from "@apollo/client";
import { useAuthState } from "@artifactlabs/react-auth";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import { useCallback, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { getUserQuery } from "@/api/queries/user";
import Header from "@/components/Header";
import Drawer from "@/components/Layout/Drawer";
import Modal from "@/components/Layout/Modal";
import SEO from "@/components/SEO";
import { GetUserQuery, GetUserQueryVariables } from "@/gql/graphql";
import { useOpenModal } from "@/hooks/useModal";
import { ReskinningConfigState } from "@/recoil/reskinningConfig/atom";
import ReskinningConfigAtom from "@/recoil/reskinningConfig/atom";
import UserAtom from "@/recoil/user";

interface Props {
  withLogo: boolean;
  disableDefaultSEO?: boolean;
  showSubscribeNewsletter?: boolean;
  pageProps?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pages?: Array<any>;
  };
  children?: React.ReactNode;
  reskinningConfig?: ReskinningConfigState;
}

const MainLayout: React.FunctionComponent<Props> = ({
  children,
  withLogo = false,
  disableDefaultSEO = false,
  reskinningConfig,
}) => {
  const { isAuthenticated, user, isLoading, error, logout } = useAuthState();
  const openModal = useOpenModal();
  const [getUser, { loading: getUserLoading }] = useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    getUserQuery,
    {
      fetchPolicy: "network-only",
    },
  );
  const [userState, setUserAtom] = useRecoilState(UserAtom);
  const setReskinningConfigState = useSetRecoilState(ReskinningConfigAtom);

  useEffect(() => {
    if (reskinningConfig) setReskinningConfigState(reskinningConfig);
  }, [reskinningConfig, setReskinningConfigState]);

  const updateUserState = useCallback(async () => {
    try {
      if (user) {
        setUserAtom(currUserAtom => ({
          ...currUserAtom,
          profile: "loading",
        }));
        const { data } = await getUser();
        if (data?.user?.profile) {
          setUserAtom(currUserAtom => ({
            ...currUserAtom,
            profile: data?.user?.profile,
          }));
        } else {
          openModal("USER_PROFILE", {}, { isClosable: false });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [getUser, openModal, setUserAtom, user]);

  useEffect(() => {
    if (error) {
      logout("/");
    }
    (async () => {
      if (isAuthenticated && !isLoading && !getUserLoading && !userState?.profile) {
        await updateUserState();
      }
    })();
  }, [
    updateUserState,
    userState?.profile,
    getUserLoading,
    isAuthenticated,
    isLoading,
    error,
    logout,
  ]);

  return (
    <div className="">
      {!disableDefaultSEO && <SEO />}
      <Header />
      <div className="mx-auto flex h-fit w-screen max-w-[2440px] flex-col items-stretch overflow-y-scroll">
        <main
          className={clsMerge(
            "flex flex-col",
            withLogo ? "min-h-[calc(100vh-98px)]" : "min-h-[calc(100vh-74px)]",
          )}
        >
          {children}
        </main>
      </div>

      <Modal />
      <Drawer />
    </div>
  );
};

export default MainLayout;
