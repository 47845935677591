const general = {
  company_display_key: "Artevault",
  favicon: "/favicon/artifactlabs.png",
  items: {
    dir: "https://__BUCKET_NAME__.s3.ap-southeast-1.amazonaws.com/general",
    displayPrefix: "Item",
    prefix: "img",
    format: "png",
    numberOfItems: 12,
  },
  isS3Based: true,
  currency: "US",
  database: "vault-poc-filecoin-staging",
};

const SITE_ID = process.env.SITE_ID || "general";

const configs = {
  general: general,
};
const reskinningConfig = (configs as any)[SITE_ID] || {};

export default reskinningConfig;
